@import 'variables';

.visible-xs,
.visible-sm,
.visible-md,
.visible-lg {
  display: none !important;
}

@media only screen and (max-width: $tablet-breakpoint - 1) {
  .visible-xs {
    display: block !important;
  }

  table.visible-xs {
    display: table !important;
  }

  tr.visible-xs {
    display: table-row !important;
  }

  th.visible-xs,
  td.visible-xs {
    display: table-cell !important;
  }
}

@media only screen and (min-width: $tablet-breakpoint) and (max-width: $desktop-breakpoint - 1) {
  .visible-sm {
    display: block !important;
  }

  table.visible-sm {
    display: table !important;
  }

  tr.visible-sm {
    display: table-row !important;
  }

  th.visible-sm,
  td.visible-sm {
    display: table-cell !important;
  }
}

@media only screen and (min-width: $desktop-breakpoint) and (max-width: $large-desktop-breakpoint - 1) {
  .visible-md {
    display: block !important;
  }

  table.visible-md {
    display: table !important;
  }

  tr.visible-md {
    display: table-row !important;
  }

  th.visible-md,
  td.visible-md {
    display: table-cell !important;
  }
}

@media only screen and (min-width: $large-desktop-breakpoint) {
  .visible-lg {
    display: block !important;
  }

  table.visible-lg {
    display: table !important;
  }

  tr.visible-lg {
    display: table-row !important;
  }

  th.visible-lg,
  td.visible-lg {
    display: table-cell !important;
  }
}

@media only screen and (max-width: $tablet-breakpoint - 1) {
  .hidden-xs {
    display: none !important;
  }
}

@media only screen and (min-width: $tablet-breakpoint) and (max-width: $desktop-breakpoint - 1) {
  .hidden-sm {
    display: none !important;
  }
}

@media only screen and (min-width: $desktop-breakpoint) and (max-width: $large-desktop-breakpoint - 1) {
  .hidden-md {
    display: none !important;
  }
}

@media only screen and (min-width: $large-desktop-breakpoint) {
  .hidden-lg {
    display: none !important;
  }
}
