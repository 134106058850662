@import 'variables';

@each $index, $color in $streamColors {
  [data-stream-color-index='#{$index}'] {
    &.stream-color,
    .stream-color {
      color: $color;
    }

    &.stream-border-color,
    .stream-border-color {
      border-color: $color;
    }

    &.stream-background-color {
      background-color: $color;
    }
  }
}
