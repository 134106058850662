@import 'variables';
@import 'mixins';
@import 'flags';
@import 'streams';
@import 'animations';
@import 'embed-utils';
@import 'visibility-utils';
@import 'shared';
@import 'froala-style';

:root {
  --informa-ribbon-height: 36px;

  --magnifying-glass-search-height: 46px;
  --magnifying-glass-search-min-width: 280px;
  --magnifying-glass-search-color: #fff;
  --magnifying-glass-search-input-background-color: rgba(255, 255, 255, 0.2);
  --magnifying-glass-search-icon-background-color: rgba(255, 255, 255, 0.4);
  --magnifying-glass-search-border-radius: 23px;
  --magnifying-glass-search-box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.12);
}

html {
  scroll-behavior: smooth;
}

body {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.4;
  color: #000;
  background-color: #fff;
  -webkit-font-smoothing: antialiased;
}

strong {
  font-weight: bold;
}

em {
  font-style: italic;
}

p + p {
  margin-top: 1em;
}

hr {
  height: 1px;
  overflow: hidden;
  background-color: #333;
  border: none;
}

.text-left {
  @include text-align(left);
}

.text-right {
  @include text-align(right);
}

.text-center {
  text-align: center;
}

.align-middle-helper {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.full-width {
  width: 100% !important;
}

.pull-right {
  @include float(right);
}

.pull-left {
  @include float(left);
}

.hidden,
[hidden] {
  display: none !important;
}

.text-hidden {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.uppercase {
  text-transform: uppercase !important;
}

.lowercase {
  text-transform: lowercase !important;
}

.capitalize {
  text-transform: capitalize !important;
}

.sentence {
  text-transform: lowercase !important;

  &:first-letter {
    text-transform: uppercase !important;
  }
}

.no-shadow {
  box-shadow: none !important;

  &:hover {
    box-shadow: none !important;
  }
}

.no-padding {
  padding: 0 0 0 0 !important;

  &-top {
    padding-top: 0 !important;
  }

  &-right {
    padding-right: 0 !important;
  }

  &-bottom {
    padding-bottom: 0 !important;
  }

  &-left {
    padding-left: 0 !important;
  }
}

.formatted-text {
  p + p,
  p + ul,
  p + ol,
  ul + p,
  ol + p,
  ul + ol,
  ol + ul {
    margin-top: 1em;
  }

  img {
    max-width: 100%;
  }

  b,
  strong {
    font-weight: bold;
  }

  i,
  em {
    font-style: italic;
  }

  p {
    //line-height: 22px;
  }

  a,
  a:hover {
    color: inherit;
  }

  ol,
  ul {
    @include padding-left(40px);

    li {
      margin: 10px 0;

      > ol,
      > ul {
        margin-top: 10px;
      }

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  ul {
    list-style: disc;
  }

  ol {
    list-style: decimal;
  }

  h2 {
    font-size: 36px;
    font-weight: 500;
    margin: 0 0 40px;
    line-height: 1.5;
  }

  h3 {
    font-size: 28px;
    font-weight: 300;
    margin: 0 0 40px;
    line-height: 1.2;
  }

  h4 {
    font-size: 25px;
    font-weight: 700;
    margin: 0 0 30px;
    line-height: 1.4;
  }

  h5 {
    font-size: 21px;
    font-weight: 700;
    margin: 0 0 30px;
    line-height: 1.4;
  }

  h6 {
    font-size: 18px;
    font-weight: 700;
    margin: 0 0 30px;
    line-height: 1.4;
  }

  sub {
    font-size: small;
    vertical-align: sub;
  }

  sup {
    font-size: small;
    vertical-align: super;
  }

  $table-border: 1px solid #ccc;

  .table-wrapper {
    overflow-x: auto;

    td {
      min-width: 279px;
    }
  }

  table {
    font-size: 16px;
    line-height: 24px;
    border: $table-border;
    border-collapse: collapse;
    empty-cells: show;

    thead,
    tfoot {
      td,
      th {
        font-weight: bold;
        vertical-align: middle;
        background-color: #ececec;
      }
    }

    th,
    td {
      padding: 10px 15px;
      border: $table-border;
      vertical-align: middle;
    }

    th {
      font-weight: bold;
      background-color: #ececec;
    }

    &.without-borders {
      border: none;

      td,
      th {
        border: none;
      }
    }
  }
}

.prevent-scroll {
  overflow: hidden;
}

.loaded .material-icons {
  opacity: 1;
}

.visual-editor-active-section {
  border: 5px solid yellow;
}

#onetrust-consent-sdk #onetrust-banner-sdk {
  @include mq($from: desktop) {
    width: 92%;
  }
}
