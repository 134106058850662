.f32 {
  display: inline-block;
  width: 32px;
  height: 32px;
  margin: -8px;
  line-height: 32px;
  vertical-align: middle;
  transform: scale(0.5);
  background: url('../assets/images/flags-sprite.png') no-repeat;
}

.f32.ad {
  background-position: 0 -704px;
}

.f32.ae {
  background-position: 0 -736px;
}

.f32.af {
  background-position: 0 -768px;
}

.f32.ag {
  background-position: 0 -800px;
}

.f32.ai {
  background-position: 0 -832px;
}

.f32.al {
  background-position: 0 -864px;
}

.f32.am {
  background-position: 0 -896px;
}

.f32.ao {
  background-position: 0 -928px;
}

.f32.aq {
  background-position: 0 -960px;
}

.f32.ar {
  background-position: 0 -992px;
}

.f32.as {
  background-position: 0 -1024px;
}

.f32.at {
  background-position: 0 -1056px;
}

.f32.au {
  background-position: 0 -1088px;
}

.f32.aw {
  background-position: 0 -1120px;
}

.f32.ax {
  background-position: 0 -1152px;
}

.f32.az {
  background-position: 0 -1184px;
}

.f32.ba {
  background-position: 0 -1216px;
}

.f32.bb {
  background-position: 0 -1248px;
}

.f32.bd {
  background-position: 0 -1280px;
}

.f32.be {
  background-position: 0 -1312px;
}

.f32.bf {
  background-position: 0 -1344px;
}

.f32.bg {
  background-position: 0 -1376px;
}

.f32.bh {
  background-position: 0 -1408px;
}

.f32.bi {
  background-position: 0 -1440px;
}

.f32.bj {
  background-position: 0 -1472px;
}

.f32.bm {
  background-position: 0 -1504px;
}

.f32.bn {
  background-position: 0 -1536px;
}

.f32.bo {
  background-position: 0 -1568px;
}

.f32.br {
  background-position: 0 -1600px;
}

.f32.bs {
  background-position: 0 -1632px;
}

.f32.bt {
  background-position: 0 -1664px;
}

.f32.bw {
  background-position: 0 -1696px;
}

.f32.by {
  background-position: 0 -1728px;
}

.f32.bz {
  background-position: 0 -1760px;
}

.f32.ca {
  background-position: 0 -1792px;
}

.f32.cd {
  background-position: 0 -1824px;
}

.f32.cf {
  background-position: 0 -1856px;
}

.f32.cg {
  background-position: 0 -1888px;
}

.f32.ch {
  background-position: 0 -1920px;
}

.f32.ci {
  background-position: 0 -1952px;
}

.f32.ck {
  background-position: 0 -1984px;
}

.f32.cl {
  background-position: 0 -2016px;
}

.f32.cm {
  background-position: 0 -2048px;
}

.f32.cn {
  background-position: 0 -2080px;
}

.f32.co {
  background-position: 0 -2112px;
}

.f32.cr {
  background-position: 0 -2144px;
}

.f32.cu {
  background-position: 0 -2176px;
}

.f32.cv {
  background-position: 0 -2208px;
}

.f32.cy {
  background-position: 0 -2240px;
}

.f32.cz {
  background-position: 0 -2272px;
}

.f32.de {
  background-position: 0 -2304px;
}

.f32.dj {
  background-position: 0 -2336px;
}

.f32.dk {
  background-position: 0 -2368px;
}

.f32.dm {
  background-position: 0 -2400px;
}

.f32.do {
  background-position: 0 -2432px;
}

.f32.dz {
  background-position: 0 -2464px;
}

.f32.ec {
  background-position: 0 -2496px;
}

.f32.ee {
  background-position: 0 -2528px;
}

.f32.eg {
  background-position: 0 -2560px;
}

.f32.eh {
  background-position: 0 -2592px;
}

.f32.er {
  background-position: 0 -2624px;
}

.f32.es {
  background-position: 0 -2656px;
}

.f32.et {
  background-position: 0 -2688px;
}

.f32.fi {
  background-position: 0 -2720px;
}

.f32.fj {
  background-position: 0 -2752px;
}

.f32.fm {
  background-position: 0 -2784px;
}

.f32.fo {
  background-position: 0 -2816px;
}

.f32.fr {
  background-position: 0 -2848px;
}

.f32.bl,
.f32.cp,
.f32.mf,
.f32.yt {
  background-position: 0 -2848px;
}

.f32.ga {
  background-position: 0 -2880px;
}

.f32.en,
.f32.gb {
  background-position: 0 -2912px;
}

.f32.sh {
  background-position: 0 -2912px;
}

.f32.gd {
  background-position: 0 -2944px;
}

.f32.ge {
  background-position: 0 -2976px;
}

.f32.gg {
  background-position: 0 -3008px;
}

.f32.gh {
  background-position: 0 -3040px;
}

.f32.gi {
  background-position: 0 -3072px;
}

.f32.gl {
  background-position: 0 -3104px;
}

.f32.gm {
  background-position: 0 -3136px;
}

.f32.gn {
  background-position: 0 -3168px;
}

.f32.gp {
  background-position: 0 -3200px;
}

.f32.gq {
  background-position: 0 -3232px;
}

.f32.gr {
  background-position: 0 -3264px;
}

.f32.gt {
  background-position: 0 -3296px;
}

.f32.gu {
  background-position: 0 -3328px;
}

.f32.gw {
  background-position: 0 -3360px;
}

.f32.gy {
  background-position: 0 -3392px;
}

.f32.hk {
  background-position: 0 -3424px;
}

.f32.hn {
  background-position: 0 -3456px;
}

.f32.hr {
  background-position: 0 -3488px;
}

.f32.ht {
  background-position: 0 -3520px;
}

.f32.hu {
  background-position: 0 -3552px;
}

.f32.id {
  background-position: 0 -3584px;
}

.f32.mc {
  background-position: 0 -3584px;
}

.f32.ie {
  background-position: 0 -3616px;
}

.f32.il {
  background-position: 0 -3648px;
}

.f32.im {
  background-position: 0 -3680px;
}

.f32.in {
  background-position: 0 -3712px;
}

.f32.iq {
  background-position: 0 -3744px;
}

.f32.ir {
  background-position: 0 -3776px;
}

.f32.is {
  background-position: 0 -3808px;
}

.f32.it {
  background-position: 0 -3840px;
}

.f32.je {
  background-position: 0 -3872px;
}

.f32.jm {
  background-position: 0 -3904px;
}

.f32.jo {
  background-position: 0 -3936px;
}

.f32.jp {
  background-position: 0 -3968px;
}

.f32.ke {
  background-position: 0 -4000px;
}

.f32.kg {
  background-position: 0 -4032px;
}

.f32.kh {
  background-position: 0 -4064px;
}

.f32.ki {
  background-position: 0 -4096px;
}

.f32.km {
  background-position: 0 -4128px;
}

.f32.kn {
  background-position: 0 -4160px;
}

.f32.kp {
  background-position: 0 -4192px;
}

.f32.kr {
  background-position: 0 -4224px;
}

.f32.kw {
  background-position: 0 -4256px;
}

.f32.ky {
  background-position: 0 -4288px;
}

.f32.kz {
  background-position: 0 -4320px;
}

.f32.la {
  background-position: 0 -4352px;
}

.f32.lb {
  background-position: 0 -4384px;
}

.f32.lc {
  background-position: 0 -4416px;
}

.f32.li {
  background-position: 0 -4448px;
}

.f32.lk {
  background-position: 0 -4480px;
}

.f32.lr {
  background-position: 0 -4512px;
}

.f32.ls {
  background-position: 0 -4544px;
}

.f32.lt {
  background-position: 0 -4576px;
}

.f32.lu {
  background-position: 0 -4608px;
}

.f32.lv {
  background-position: 0 -4640px;
}

.f32.ly {
  background-position: 0 -4672px;
}

.f32.ma {
  background-position: 0 -4704px;
}

.f32.md {
  background-position: 0 -4736px;
}

.f32.me {
  background-position: 0 -4768px;
}

.f32.mg {
  background-position: 0 -4800px;
}

.f32.mh {
  background-position: 0 -4832px;
}

.f32.mk {
  background-position: 0 -4864px;
}

.f32.ml {
  background-position: 0 -4896px;
}

.f32.mm {
  background-position: 0 -4928px;
}

.f32.mn {
  background-position: 0 -4960px;
}

.f32.mo {
  background-position: 0 -4992px;
}

.f32.mq {
  background-position: 0 -5024px;
}

.f32.mr {
  background-position: 0 -5056px;
}

.f32.ms {
  background-position: 0 -5088px;
}

.f32.mt {
  background-position: 0 -5120px;
}

.f32.mu {
  background-position: 0 -5152px;
}

.f32.mv {
  background-position: 0 -5184px;
}

.f32.mw {
  background-position: 0 -5216px;
}

.f32.mx {
  background-position: 0 -5248px;
}

.f32.my {
  background-position: 0 -5280px;
}

.f32.mz {
  background-position: 0 -5312px;
}

.f32.na {
  background-position: 0 -5344px;
}

.f32.nc {
  background-position: 0 -5376px;
}

.f32.ne {
  background-position: 0 -5408px;
}

.f32.ng {
  background-position: 0 -5440px;
}

.f32.ni {
  background-position: 0 -5472px;
}

.f32.nl {
  background-position: 0 -5504px;
}

.f32.bq {
  background-position: 0 -5504px;
}

.f32.no {
  background-position: 0 -5536px;
}

.f32.bv,
.f32.nq,
.f32.sj {
  background-position: 0 -5536px;
}

.f32.np {
  background-position: 0 -5568px;
}

.f32.nr {
  background-position: 0 -5600px;
}

.f32.nz {
  background-position: 0 -5632px;
}

.f32.om {
  background-position: 0 -5664px;
}

.f32.pa {
  background-position: 0 -5696px;
}

.f32.pe {
  background-position: 0 -5728px;
}

.f32.pf {
  background-position: 0 -5760px;
}

.f32.pg {
  background-position: 0 -5792px;
}

.f32.ph {
  background-position: 0 -5824px;
}

.f32.pk {
  background-position: 0 -5856px;
}

.f32.pl {
  background-position: 0 -5888px;
}

.f32.pr {
  background-position: 0 -5920px;
}

.f32.ps {
  background-position: 0 -5952px;
}

.f32.pt {
  background-position: 0 -5984px;
}

.f32.pw {
  background-position: 0 -6016px;
}

.f32.py {
  background-position: 0 -6048px;
}

.f32.qa {
  background-position: 0 -6080px;
}

.f32.re {
  background-position: 0 -6112px;
}

.f32.ro {
  background-position: 0 -6144px;
}

.f32.rs {
  background-position: 0 -6176px;
}

.f32.ru {
  background-position: 0 -6208px;
}

.f32.rw {
  background-position: 0 -6240px;
}

.f32.sa {
  background-position: 0 -6272px;
}

.f32.sb {
  background-position: 0 -6304px;
}

.f32.sc {
  background-position: 0 -6336px;
}

.f32.sd {
  background-position: 0 -6368px;
}

.f32.se {
  background-position: 0 -6400px;
}

.f32.sg {
  background-position: 0 -6432px;
}

.f32.si {
  background-position: 0 -6464px;
}

.f32.sk {
  background-position: 0 -6496px;
}

.f32.sl {
  background-position: 0 -6528px;
}

.f32.sm {
  background-position: 0 -6560px;
}

.f32.sn {
  background-position: 0 -6592px;
}

.f32.so {
  background-position: 0 -6624px;
}

.f32.sr {
  background-position: 0 -6656px;
}

.f32.st {
  background-position: 0 -6688px;
}

.f32.sv {
  background-position: 0 -6720px;
}

.f32.sy {
  background-position: 0 -6752px;
}

.f32.sz {
  background-position: 0 -6784px;
}

.f32.tc {
  background-position: 0 -6816px;
}

.f32.td {
  background-position: 0 -6848px;
}

.f32.tg {
  background-position: 0 -6880px;
}

.f32.th {
  background-position: 0 -6912px;
}

.f32.tj {
  background-position: 0 -6944px;
}

.f32.tl {
  background-position: 0 -6976px;
}

.f32.tm {
  background-position: 0 -7008px;
}

.f32.tn {
  background-position: 0 -7040px;
}

.f32.to {
  background-position: 0 -7072px;
}

.f32.tr {
  background-position: 0 -7104px;
}

.f32.tt {
  background-position: 0 -7136px;
}

.f32.tv {
  background-position: 0 -7168px;
}

.f32.tw {
  background-position: 0 -7200px;
}

.f32.tz {
  background-position: 0 -7232px;
}

.f32.ua {
  background-position: 0 -7264px;
}

.f32.ug {
  background-position: 0 -7296px;
}

.f32.us {
  background-position: 0 -7328px;
}

.f32.uy {
  background-position: 0 -7360px;
}

.f32.uz {
  background-position: 0 -7392px;
}

.f32.va {
  background-position: 0 -7424px;
}

.f32.vc {
  background-position: 0 -7456px;
}

.f32.ve {
  background-position: 0 -7488px;
}

.f32.vg {
  background-position: 0 -7520px;
}

.f32.vi {
  background-position: 0 -7552px;
}

.f32.vn {
  background-position: 0 -7584px;
}

.f32.vu {
  background-position: 0 -7616px;
}

.f32.ws {
  background-position: 0 -7648px;
}

.f32.ye {
  background-position: 0 -7680px;
}

.f32.za {
  background-position: 0 -7712px;
}

.f32.zm {
  background-position: 0 -7744px;
}

.f32.zw {
  background-position: 0 -7776px;
}

.f32.sx {
  background-position: 0 -7808px;
}

.f32.cw {
  background-position: 0 -7840px;
}

.f32.ss {
  background-position: 0 -7872px;
}

.f32.nu {
  background-position: 0 -7904px;
}
